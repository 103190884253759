/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-success-story-of-audibene-and-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-success-story-of-audibene-and-hearcom",
    "aria-label": "the success story of audibene and hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The success story of audibene and hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Miami, June 6th."), " When Dr. Marco Vietor and Paul Crusius, alumni of WHU, set out to make a positive difference in the world of hearing, they were determined to expand their efforts across the Atlantic. But first, they decided to start in Berlin by creating a hearing aid company called audibene."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Founded in 2012, audibene is the largest online provider of expert hearing care and modern hearing aids in Europe. audibene delivers the best customer experience and the most advanced hearing technology to people with hearing loss. Instead of focusing on the typical hearing aid customer in the market, Dr. Marco Vietor and Paul Crusius saw a need to target first-time users who are younger and more tech-savvy. This model proved to be successful, generating more than 10 million U.S. dollars in revenue in 2014. Equipped with the right knowledge and know-how to grow their business globally, these two graduates of WHU were confident they could recreate the same success in the U.S."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com made its first appearance in North America in 2015. Applying the same business model that made audibene a powerhouse in the European market, Dr. Marco Vietor and Paul Crusius started working diligently on impacting the U.S. market. And in just a few months, hear.com’s team of hearing experts, audiologists and network of Partner Providers began changing lives at an exponential rate."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com’s marketing strategy aims at changing the perception of hearing aids by promoting smaller, smarter and stronger devices. Their message appeals to a younger audience interested in the newest technology on par with smartphones and computers. In 2018, hear.com (globally) will bring in over 150 million U.S. dollars in revenue, grow their partner network to 1,700+ offices across the U.S., and reach the highest customer satisfaction rating in the industry. Needless to say, the future looks very bright for hear.com. “One in three people over fifty has hearing problems, but only 20% of them actually wear hearing aids. That’s a lot of potential and enough work for decades,” says Paul Crusius."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During a WHU Founders’ Conference in 2015, Dr. Marco Vietor encouraged young entrepreneurs to “keep the entrepreneurial spirit alive, even after early growth and success.” In terms of management, hear.com operates without an organizational structure. According to Dr. Vietor, this helps to stop any form of corporatism from creeping into their business model. And with over 800 staff in nine countries, it’s safe to say this strategy will ensure hear.com’s longevity in the global hearing aid market."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "audibene and hear.com continue to hire professionals who share the same vision, passion and drive that made them the best hearing care companies in the world. Many of those employed are graduates of WHU. In fact, as of April 2018, audibene and hear.com have employed 15 former WHU students to occupy roles in sales, business development and marketing. And that number continues to grow as the global leaders of the hearing care industry change the lives of millions of people all around the world."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
